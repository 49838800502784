import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { formatPhoneNumberIntl, isPossiblePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'

import { RingLoader } from "react-spinners";

import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import ApiHelper from '../../api/apiHelper';
const apiHelper = new ApiHelper();

const CompleteRegistration = () => {
	const nameInputRef = useRef();
	const phoneNumberInputRef = useRef();
	const phoneNumberErrorRef = useRef();
	const referralErrorRef = useRef();

	const referralFacebookCheckboxRef = useRef();
	const referralSearchCheckboxRef = useRef();
	const referralGoogleAdsCheckboxRef = useRef();
	const referralMemberCheckboxRef = useRef();
	const referralBusinessFocusCheckboxRef = useRef();
	const referralStephenChannelCheckboxRef = useRef();
	const referralAIBookCheckboxRef = useRef();
	const referralEtNetCheckboxRef = useRef();
	const referralYouTubeCheckboxRef = useRef();
	const referralArticleCheckboxRef = useRef();
	const referralEmailCheckboxRef = useRef();
	const referralOtherCheckboxRef = useRef();
	const referralOtherInputDivRef = useRef();
	const referralOtherInputRef = useRef();

	const [ loading, setLoading ] = useState(true);

	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const history = useHistory();

	const onToggleOtherCheckbox = () => {
		if (referralOtherCheckboxRef.current.checked) {
			referralOtherInputDivRef.current.classList.remove("d-none");
			referralOtherInputRef.current.required = true;
		} else {
			referralOtherInputDivRef.current.classList.add("d-none");
			referralOtherInputRef.current.required = false;
		}
	};

	const getUserDetails = async () => {
		setLoading(true);

		try {
			var userResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/user",
				"GET"
			);

			if (userResponse.data.completeRegistration) {
				history.push("/dashboard");
			} else {
				setLoading(false);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const completeRegistration = async (event) => {
		event.preventDefault();

		const phoneNumber = phoneNumberInputRef.current.value.replaceAll(" ", "");

		if (!phoneNumber || !isPossiblePhoneNumber(phoneNumber)) {
			phoneNumberErrorRef.current.classList.remove("d-none");
			return false;
		} else {
			phoneNumberErrorRef.current.classList.add("d-none");
		}

		if (referralFacebookCheckboxRef.current.checked
			|| referralSearchCheckboxRef.current.checked
			|| referralGoogleAdsCheckboxRef.current.checked
			|| referralMemberCheckboxRef.current.checked
			|| referralBusinessFocusCheckboxRef.current.checked
			|| referralStephenChannelCheckboxRef.current.checked
			|| referralAIBookCheckboxRef.current.checked
			|| referralEtNetCheckboxRef.current.checked
			|| referralYouTubeCheckboxRef.current.checked
			|| referralArticleCheckboxRef.current.checked
			|| referralEmailCheckboxRef.current.checked
			|| referralOtherCheckboxRef.current.checked) {
				referralErrorRef.current.classList.add("d-none");
		} else {
			referralErrorRef.current.classList.remove("d-none");
			return false;
		}

		setLoading(true);

		try {
			var verifyResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"users/complete-registration",
				"POST",
				{
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				JSON.stringify({
					name: nameInputRef.current.value,
					phoneNumber: phoneNumber,
					referral: {
						facebook: referralFacebookCheckboxRef.current.checked,
						search: referralSearchCheckboxRef.current.checked,
						googleAds: referralGoogleAdsCheckboxRef.current.checked,
						memberReferral: referralMemberCheckboxRef.current.checked,
						businessFocus: referralBusinessFocusCheckboxRef.current.checked,
						stephenChannel: referralStephenChannelCheckboxRef.current.checked,
						aiBook: referralAIBookCheckboxRef.current.checked,
						etNet: referralEtNetCheckboxRef.current.checked,
						youtube: referralYouTubeCheckboxRef.current.checked,
						article: referralArticleCheckboxRef.current.checked,
						email: referralEmailCheckboxRef.current.checked,
						other: referralOtherCheckboxRef.current.checked,
						otherInput: referralOtherInputRef.current.value,
					},
				})
			);

			var verified = verifyResponse.data;

			if (verified) {
				ReactGA.event('sign_up');
				ReactPixel.track('CompleteRegistration');

				history.push("/dashboard");
			} else {
				alert(verifyResponse.errors[0].message);
				setLoading(false);
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	const phoneOnChange = (event) => {
		var formatted = formatPhoneNumberIntl(phoneNumberInputRef.current.value);
		
		if (formatted.length >= phoneNumberInputRef.current.value.length) {
			phoneNumberInputRef.current.value = formatted;
		}
	}

	useEffect(() => {
		getUserDetails();
	}, [ ]);

	const renderForm = () => {
		return (
			<>
				<h2 className="my-3">{ t("user.post-registration.complete-registration") }</h2>
				<Form onSubmit={ completeRegistration } >
					<FloatingLabel controlId="username" label={ t("user.data.name") } className="mb-3">
						<Form.Control ref={ nameInputRef } type="text" aria-label={ t("user.data.name") } required />
					</FloatingLabel>

					<FloatingLabel controlId="phone" label={ t("user.data.phone") } className="mb-3">
						<Form.Control ref={ phoneNumberInputRef } type="tel" aria-label={ t("user.data.phone") } required onChange={phoneOnChange} aria-describedby="phone-number-note" />
						<Form.Text id="phone-number-note" muted>
							{t("user.post-registration.phone-desc")}
						</Form.Text>
					</FloatingLabel>

					<p ref={ phoneNumberErrorRef } className="text-danger d-none"><FontAwesomeIcon icon={ faTimes } className="me-2" />{ t("user.data.error.invalid-phone") }</p>

					<h4 className="my-3">{ t("user.post-registration.referral.title") }</h4>
					
					<div className="row">
						<div className="col-12 col-lg-6">
							<div className="mb-3">
								<Form.Check ref={ referralFacebookCheckboxRef } id="facebook" type="checkbox" name="referral" label={ t("user.post-registration.referral.facebook") } />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralSearchCheckboxRef } type="checkbox" id="search" name="referral" label={ t("user.post-registration.referral.search") } />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralGoogleAdsCheckboxRef } type="checkbox" id="google-ads" name="referral" label={ t("user.post-registration.referral.google-ads") } />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralMemberCheckboxRef } type="checkbox" id="member-referral" name="referral" label={ t("user.post-registration.referral.member-referral") } />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralBusinessFocusCheckboxRef } id="business-focus" type="checkbox" name="referral" label={ t("user.post-registration.referral.business-focus") } />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralStephenChannelCheckboxRef } type="checkbox" id="stephen-channel" name="referral" label="志雲頻道 stephenchannel" />
							</div>
						</div>

						<div className="col-12 col-lg-6">

							<div className="mb-3">
								<Form.Check ref={ referralAIBookCheckboxRef } type="checkbox" id="ai-book" name="referral" label="AI投資贏接未來" />
							</div>
							
							<div className="mb-3">
								<Form.Check ref={ referralEtNetCheckboxRef } type="checkbox" id="et-net" name="referral" label="ET Net 經濟通" />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralYouTubeCheckboxRef } type="checkbox" id="youtube" name="referral" label="YouTube" />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralArticleCheckboxRef } type="checkbox" id="article" name="referral" label={ t("user.post-registration.referral.article") } />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralEmailCheckboxRef } type="checkbox" id="email" name="referral" label={ t("user.post-registration.referral.email") } />
							</div>

							<div className="mb-3">
								<Form.Check ref={ referralOtherCheckboxRef } type="checkbox" id="other" name="referral" label={ t("user.post-registration.referral.other") } onChange={ onToggleOtherCheckbox } />
							</div>

							<div ref={ referralOtherInputDivRef } className="mb-3 d-none">
								<FloatingLabel controlId="other-referral" label={ t("user.post-registration.referral.title") } className="mb-3">
									<Form.Control ref={ referralOtherInputRef } type="text" aria-label={ t("user.post-registration.referral.title") } />
								</FloatingLabel>
							</div>
						</div>
					</div>

					<p ref={ referralErrorRef } className="text-danger d-none"><FontAwesomeIcon icon={ faTimes } className="me-2" />{ t("user.post-registration.referral.at-least-one") }</p>

					<Button variant="finetic-blue" type="submit">{ t("user.post-registration.complete-registration") }</Button>
				</Form>
			</>
		);
	};

	return (
		<div className="row">
			<div className="col-12 col-lg-6">
				{
					loading ?
						<div id="loading-container" className="col-12 d-flex flex-column justify-content-around align-items-center my-5">
							<RingLoader color="#005493" />
						</div> : renderForm()
				}
			</div>
		</div>
	);
};

export default CompleteRegistration;
