import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation } from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

const exchanges = [
	{
		id: "HKEX",
		name: "🇭🇰 HK",
		icon: "🇭🇰",
		linkSuffix: "hk",
	},
	{
		id: "US",
		name: "🇺🇸 US",
		icon: "🇺🇸",
		linkSuffix: "us",
	},
	{
		id: "JP",
		name: "🇯🇵 JP",
		icon: "🇯🇵",
		linkSuffix: "jp",
	},
	{
		id: "CN",
		name: "🇨🇳 CN",
		icon: "🇨🇳",
		linkSuffix: "cn",
	},
	{
		id: "TW",
		name: "🇹🇼 TW",
		icon: "🇹🇼",
		linkSuffix: "tw",
	},
];

const Toggle = React.forwardRef(({ children, onClick }, ref) => {
	return (
		<div ref={ ref } onClick={ onClick } >
			{ children }
		</div>
	);
});

const MarketSelector = ({ activeExchange, linkPrefix }) => {
	const [ showDot, setShowDot ] = useState({
		hk: false,
		us: false,
		jp: false,
		cn: false,
		tw: false,
		show: false,
	});

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();

	const getTabStatus = async () => {
		let [ finlistHK, finlistUS, finlistJP, finlistCN, finlistTW, trendHK, trendUS, trendJP, trendCN, trendTW ] = await Promise.all([
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"finlist/HKEX/last-updated",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"finlist/US/last-updated",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"finlist/JP/last-updated",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"finlist/CN/last-updated",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"finlist/TW/last-updated",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"markettrend/HKEX/last-updated",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"markettrend/US/last-updated",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"markettrend/CN/last-updated",
				"GET"
			),
			apiHelper.request(
				getAccessTokenSilently,
				location,
				"markettrend/TW/last-updated",
				"GET"
			),
        ]);

		var pageLastUpdated = {
			finlist: {
				hk: finlistHK ? (finlistHK.data !== "" ? new Date(finlistHK.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
				us: finlistUS ? (finlistUS.data !== "" ? new Date(finlistUS.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
				jp: finlistJP ? (finlistJP.data !== "" ? new Date(finlistJP.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
				cn: finlistCN ? (finlistCN.data !== "" ? new Date(finlistCN.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
				tw: finlistTW ? (finlistTW.data !== "" ? new Date(finlistTW.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
			},
			markettrend: {
				hk: trendHK ? (trendHK.data !== "" ? new Date(trendHK.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
				us: trendUS ? (trendUS.data !== "" ? new Date(trendUS.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
				jp: trendJP ? (trendJP.data !== "" ? new Date(trendJP.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
				cn: trendCN ? (trendCN.data !== "" ? new Date(trendCN.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
				tw: trendTW ? (trendTW.data !== "" ? new Date(trendTW.data) : new Date("2021-09-01")) : new Date("2021-09-01"),
			},
		};

		localStorage.getItem("page-last-viewed");

		let pageLastViewed = localStorage.getItem("page-last-viewed");

		if (pageLastViewed) {
			pageLastViewed = JSON.parse(pageLastViewed);
			pageLastViewed = {
				finlist: {
					hk: new Date(pageLastViewed.finlist.hk),
					us: new Date(pageLastViewed.finlist.us),
					jp: new Date(pageLastViewed.finlist.jp),
					cn: new Date(pageLastViewed.finlist.cn),
					tw: new Date(pageLastViewed.finlist.tw),
				},
				markettrend: {
					hk: new Date(pageLastViewed.markettrend.hk),
					us: new Date(pageLastViewed.markettrend.us),
					jp: new Date(pageLastViewed.markettrend.jp),
					cn: new Date(pageLastViewed.markettrend.cn),
					tw: new Date(pageLastViewed.markettrend.tw),
				},
				education: {
					sundayClub: new Date(pageLastViewed.education.sundayClub),
					videos: new Date(pageLastViewed.education.videos),
				},
			};
		}

		showDot.hk = !isNaN(pageLastUpdated[linkPrefix].hk) ? pageLastUpdated[linkPrefix].hk > pageLastViewed[linkPrefix].hk : false;
		showDot.us = !isNaN(pageLastUpdated[linkPrefix].us) ? pageLastUpdated[linkPrefix].us > pageLastViewed[linkPrefix].us : false;
		showDot.jp = !isNaN(pageLastUpdated[linkPrefix].jp) ? pageLastUpdated[linkPrefix].jp > pageLastViewed[linkPrefix].jp : false;
		showDot.cn = !isNaN(pageLastUpdated[linkPrefix].cn) ? pageLastUpdated[linkPrefix].cn > pageLastViewed[linkPrefix].cn : false;
		showDot.tw = !isNaN(pageLastUpdated[linkPrefix].tw) ? pageLastUpdated[linkPrefix].tw > pageLastViewed[linkPrefix].tw : false;
		showDot.show = showDot.hk || showDot.us || showDot.cn || showDot.tw;

		setShowDot({ ...showDot });
	};

	const getExchangeDisplay = () => {
		var exchange = exchanges.filter((exchange, i) => exchange.id === activeExchange)[0];

		if (exchange === null) {
			exchange = exchanges[0];
		}
		
		return exchange.icon;
	}

	const saveMarket = (market) => {
		localStorage.setItem("user-current-market", JSON.stringify(market));

		let pageLastViewed = localStorage.getItem("page-last-viewed");
		pageLastViewed = JSON.parse(pageLastViewed);
		pageLastViewed[linkPrefix][market.linkSuffix] = new Date().getTime();
		localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
	}

	useEffect(() => {
		getTabStatus();
	}, [ ]);

	return (
		<div className="d-lg-none market-selector">
			<div className="selector-bg-wrapper">
				<div className="selector-bg-overlay">
					<div className="selector-bg-text">{ getExchangeDisplay() }</div>
				</div>
			</div>
			<div className="selector-bg"></div>
			{ showDot.show ? <div className="overlay-dot dot"></div> : null }
			<Dropdown>
				<Dropdown.Toggle as={ Toggle } id="market-selector" className="selector-label">
					{ getExchangeDisplay() }
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu dropdown-menu-right" aria-labelledby="market-selector">
					{
						exchanges.map((exchange, i) => (
							<Dropdown.Item>
								<Link key={ exchange.id } to={ "/" + linkPrefix + "-" + exchange.linkSuffix } onClick={ () => saveMarket(exchange) } >
									<div className="d-flex justify-content-between align-items-center">
										{ exchange.name }
										{ showDot[exchange.linkSuffix] ? <div className="dot"></div> : null }
									</div>
								</Link>
							</Dropdown.Item>
						))
					}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default MarketSelector;
