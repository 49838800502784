import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import {
	SideBar,
	NavBar,
	SystemToastsWrapper,
	Header,
} from './components';
import {
	Loading,
	Subscribe,
	SubscribeVerify,
	Affiliate,
	AffiliateManagement,
	FinListHK,
	// FinderHK,
	MarketTrendHK,
	ShareholdingChange,
	MarketMovers,
	StockNews,
	FinListUS,
	MarketTrendUS,
	FinListJP,
	MarketTrendJP,
	FinListCN,
	MarketTrendCN,
	FinListTW,
	MarketTrendTW,
	InteractiveChart,
	FinTrainer,
	Watchlist,
	Education,
	Tools,
	FAQ,
	Style,
} from './pages';
import {
	Dashboard,
	CompleteRegistration,
} from './pages/user';
import {
	Simulator,
	DataUpload,
	UserList,
} from './pages/admin';
import ScrollToTop from './util/ScrollToTop';
import ProtectedRoute from './util/ProtectedRoute';
import PWAPrompt from 'react-ios-pwa-prompt';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from 'react-i18next';

import ApiHelper from './api/apiHelper';
const apiHelper = new ApiHelper();

const routes = [
	{
		path: "/",
		component: FinListHK,
		routeComponent: ProtectedRoute,
		exact: true
	},
	{
		path: "/complete-registration",
		component: CompleteRegistration,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/dashboard",
		component: Dashboard,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/subscribe",
		component: Subscribe,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/subscribe-verify",
		component: SubscribeVerify,
		routeComponent: Route,
	},
	{
		path: "/admin/affiliates",
		component: AffiliateManagement,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/affiliates",
		component: Affiliate,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/finlist-hk",
		component: FinListHK,
		routeComponent: ProtectedRoute,
	},
	// {
	// 	path: "/finder-hk",
	// 	component: FinderHK,
	// 	routeComponent: ProtectedRoute,
	// },
	{
		path: "/markettrend-hk",
		component: MarketTrendHK,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/top-shareholding-change",
		component: ShareholdingChange,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/market-movers-hk",
		component: MarketMovers,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/stock-news-hk",
		component: StockNews,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/finlist-us",
		component: FinListUS,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/markettrend-us",
		component: MarketTrendUS,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/finlist-jp",
		component: FinListJP,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/markettrend-jp",
		component: MarketTrendJP,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/finlist-cn",
		component: FinListCN,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/markettrend-cn",
		component: MarketTrendCN,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/finlist-tw",
		component: FinListTW,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/markettrend-tw",
		component: MarketTrendTW,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/watchlist",
		component: Watchlist,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/interactive-chart",
		component: InteractiveChart,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/fintrainer",
		component: FinTrainer,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/education",
		component: Education,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/admin/upload",
		component: DataUpload,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/admin/user-list",
		component: UserList,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/practice/simulator",
		component: Simulator,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/tools",
		component: Tools,
		routeComponent: ProtectedRoute,
	},
	{
		path: "/faq",
		component: FAQ,
		routeComponent: ProtectedRoute,
	},
	// {
	// 	path: "/style",
	// 	component: Style,
	// 	routeComponent: Route,
	// },
];

export const history = createBrowserHistory();

const FineticAppRouter = () => {
	const [ showExtendedSideBar, setShowExtendedSideBar ] = useState(true);
	
	const toggleSideBar = () => { setShowExtendedSideBar(!showExtendedSideBar) };
	
	const { t } = useTranslation();
	const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		if (isLoading) return;

		if (isAuthenticated) {
			if (user != null) {
				ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {
					gaOptions: {
						userId: user.sub,
					},
				});
		
				ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, {
					external_id: user.sub,
				});
			}

			// Notification dots
			let pageLastViewed = localStorage.getItem("page-last-viewed");
	
			if (!pageLastViewed) {
				pageLastViewed = {
					finlist: {
						hk: new Date("2021-09-01").getTime(),
						us: new Date("2021-09-01").getTime(),
						jp: new Date("2021-09-01").getTime(),
						cn: new Date("2021-09-01").getTime(),
						tw: new Date("2021-09-01").getTime(),
					},
					markettrend: {
						hk: new Date("2021-09-01").getTime(),
						us: new Date("2021-09-01").getTime(),
						jp: new Date("2021-09-01").getTime(),
						cn: new Date("2021-09-01").getTime(),
						tw: new Date("2021-09-01").getTime(),
					},
					education: {
						sundayClub: new Date("2021-09-01").getTime(),
						videos: new Date("2021-09-01").getTime(),
					},
				};

				localStorage.setItem("page-last-viewed", JSON.stringify(pageLastViewed));
			}
		}
	}, [ isLoading, isAuthenticated ]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Router history={ history } >
			{/* <ScrollToTop /> */}
			<div id="main-container" className="container-fluid">
				<div className="row">
					<div className="col-12">
						<Header toggleSideBar={ toggleSideBar } />
						<SystemToastsWrapper />
						<div className="row row-offcanvas row-offcanvas-left">
							<div className="sidebar-offcanvas d-none d-lg-flex col-lg-3 col-xxl-2 px-0">
								<SideBar extended={ showExtendedSideBar } />
							</div>
							<div className="column col-xxl-10 col-lg-9 col-12 px-0">
								<main id="main-content">
									<div className="container-fluid">
										<Switch>
											{
												routes.map((route, i) => (
													<route.routeComponent key={ i } { ...route } />
												))
											}
											<Redirect to="/" />
										</Switch>
									</div>
								</main>
								<NavBar />
							</div>
						</div>

						<PWAPrompt
							timesToShow={ 2 }
							permanentlyHideOnDismiss={ false }
							copyTitle={ t("util.add-to-homescreen-title") }
							copyBody={ t("util.add-to-homescreen-body") }
							copyShareButtonLabel={ t("util.add-to-homescreen-step-1") }
							copyAddHomeButtonLabel={ t("util.add-to-homescreen-step-2") }
							copyClosePrompt={ t("util.add-to-homescreen-cancel") }
						/>
					</div>
				</div>
			</div>
		</Router>
	);
};

export default FineticAppRouter;
