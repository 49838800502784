import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

import { v4 as uuidv4 } from 'uuid';

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

const ProtectedContent = ({ children }) => {
	const [ allow, setAllow ] = useState(false);
	const [ hasDeviceId, setHasDeviceId ] = useState(false);

	const { user, getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();
	const location = useLocation();
	const history = useHistory();

	// const fingerprintData = useVisitorData({ linkedId: user.sub, extendedResult: true });

	const checkUserRegistrationStatus = async () => {
		if (location.pathname === "/complete-registration") {
			setAllow(true);
		} else {
			let completeRegistration = localStorage.getItem("user-complete-registration");

			if (JSON.parse(completeRegistration)) {
				setAllow(true);
				return;
			}

			try {
				var response = await apiHelper.request(
					getAccessTokenSilently,
					{ pathname: "/background-check" },
					"users/user",
					"GET"
				);

				if (response.data.completeRegistration) {
					localStorage.setItem("user-complete-registration", true);

					setAllow(true);
					return
				} else {
					history.push("/complete-registration");
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	useEffect(() => {
		let deviceId = localStorage.getItem("finetic-app-device-id");
		let deviceKey = localStorage.getItem("finetic-app-device-key");

		if (deviceKey === null) {
			localStorage.setItem("finetic-app-device-key", uuidv4());
		}

		if (deviceId === null) {
			// if (!fingerprintData.isLoading) {
			// 	if (fingerprintData.data) {
			// 		if (fingerprintData.data.visitorId) {
			// 			localStorage.setItem('finetic-app-device-id', fingerprintData.data.visitorId);
			// 			localStorage.setItem('finetic-app-device-incognito', fingerprintData.data.incognito);
			// 			setHasDeviceId(true);
			// 		}

			// 		localStorage.setItem('finetic-app-device-data', JSON.stringify(fingerprintData.data));
			// 	} else if (fingerprintData.error) {
					localStorage.setItem('finetic-app-device-id', "fp-error-no-id-available");
					localStorage.setItem('finetic-app-device-incognito', "fp-error-no-info-available");
					setHasDeviceId(true);
		// 		}
		// 	}
		// } else {
		// 	setHasDeviceId(true);
		}

		setHasDeviceId(true);
	}, [ ]);
	// }, [ fingerprintData ]);

	useEffect(() => {
		if (!isLoading && isAuthenticated && hasDeviceId) {
			checkUserRegistrationStatus();
		}

	}, [ isLoading, isAuthenticated, hasDeviceId ]);

	if (allow && hasDeviceId) {
		return children;
	}

	return null;
};

export default ProtectedContent;
